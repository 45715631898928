export const ocrDocumentColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '文档名称',
      align: 'left',
      dataIndex: 'document.name'
    },
    {
      title: '类型',
      align: 'center',
      width: '100px',
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '操作',
      align: 'center',
      width: '160px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

//文档类型
export const TypeData = function () {
  return [
    {
      value: 'WORD',
      label: '文档'
    },
    {
      value: 'EXCEL',
      label: '表格'
    },
    {
      value: 'OTHER',
      label: '其他'
    }
  ]
}