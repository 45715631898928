export const ocrDocumentVO = function () {
  return {
    ocrDocument: {
      id: null,
      orgId: null, //单位部门ID
      user: { //用户
        id: null,
        name: ''
      },
      name: '', //名称
      type: '', //文档类型（WORD文档/EXCEL表格/OTHER其他）
      ifOcr: 'false',
      state: 0
    },
    imageIds: []// 上传图片ids
  }
}