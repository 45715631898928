var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称","label-col":_vm.spotLabelCol,"wrapper-col":_vm.spotWrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name',
              {rules:[
                {type:'string',required:true,message:'名称不能为空'}
                ],trigger:'change'}
              ]),expression:"['name',\n              {rules:[\n                {type:'string',required:true,message:'名称不能为空'}\n                ],trigger:'change'}\n              ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"文档类型","label-col":_vm.spotLabelCol,"wrapper-col":_vm.spotWrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['TypeData',
                                   {rules:[{TypeData:'string',required:true,message:'请选择文档类型'}],
                                   trigger:'change',initialValue:'true'}]),expression:"['TypeData',\n                                   {rules:[{TypeData:'string',required:true,message:'请选择文档类型'}],\n                                   trigger:'change',initialValue:'true'}]"}],attrs:{"options":_vm.TypeData}})],1),_c('a-form-item',{attrs:{"label":"文档图片","label-col":_vm.spotLabelCol,"wrapper-col":_vm.spotWrapperCol}},[_c('a-upload',{attrs:{"action":_vm.actionUrl,"headers":_vm.headers,"accept":"image/*","listType":"picture-card","name":"image","fileList":_vm.fileList},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 3)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"98%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }