<template>
  <div>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="begin">开始OCR识别</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div style="display: flex;flex-wrap:wrap;justify-content:space-between;width: 100%">
        <span style="width: 33%">
            <a-card hoverable style="width: 100%">
              <img  style="width: 100%"
                    alt="example"
                    src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                    slot="cover"
              />
              <a-card-meta>
                <template slot="description"
                >请先OCR识别查看文档</template
                >
              </a-card-meta>
            </a-card>
          </span>
        <span style="width: 33%">
            <a-card hoverable style="width: 100%">
            <img
              alt="example"
              src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
              slot="cover"
            />
            <a-card-meta title="OCR文档">
              <template slot="description"
              >OCR文档内容</template
              >
            </a-card-meta>
          </a-card>
        </span>
        <span style="width: 33%">
            <a-card hoverable style="width: 100%">
            <img
              alt="example"
              src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
              slot="cover"
            />
            <a-card-meta title="OCR文档">
              <template slot="description"
              >OCR文档内容</template
              >
            </a-card-meta>
          </a-card>
        </span>
        <span style="width: 33%">
            <a-card hoverable style="width: 100%">
            <img
              alt="example"
              src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
              slot="cover"
            />
            <a-card-meta title="OCR文档">
              <template slot="description"
              >OCR文档内容</template
              >
            </a-card-meta>
          </a-card>
        </span>
      </div>
    </a-card>
  </div>
</template>

<script>
  export default {
    name: 'itemList',
    data() {
      return {}
    },
    methods: {
      loadData(id) {
      },
      begin() {
      }
    }
  }
</script>

<style scoped>
</style>